<template>
  <ValidationObserver ref="companyInfo">
<b-row v-if="user">
    <b-col>
        <b-overlay
            :show = "this.loading"
            spinner-variant="primary"
            rounded="sm"
            style="max-width: 100%;"
        >

            <b-card>
                <b-form>
                    <b-row>          
                    <!-- Company Name -->
                     <b-col lg="7">
                        <b-form-group
                        :label="$t('firstname')"
                        label-for="v-first-name"
                        >
                        
                        <b-form-input
                            id="v-first-name"
                            :placeholder="$t('firstname')"
                            v-model="supplierCompanyDetails.name"
                            disabled
                        />
                        </b-form-group>
                    </b-col>

                    <!-- Company Purpose -->
                     <b-col lg="7">
                        <b-form-group
                        :label="$t('companyPurpose')"
                        label-for="v-company-purpose"
                        >
                        
                        <b-form-input
                            id="v-company-purpose"
                            :placeholder="$t('companyPurpose')"
                            v-model="supplierCompanyDetails.purpose"
                            disabled
                        />
                        </b-form-group>
                    </b-col>

                    <!-- Company Address -->
                     <b-col lg="7">
                        <b-form-group
                        :label="$t('address')"
                        label-for="v-company-address"
                        >
                        
                        <b-form-input
                            id="v-company-address"
                            :placeholder="$t('address')"
                            v-model="supplierCompanyDetails.address"
                            disabled
                        />
                        </b-form-group>
                    </b-col>

                    <!-- Tax Id -->
                     <b-col lg="7">
                        <b-form-group
                        :label="$t('taxId')"
                        label-for="v-company-tax-id"
                        >
                        
                        <b-form-input
                            id="v-company-tax-id"
                            :placeholder="$t('taxId')"
                            v-model="supplierCompanyDetails.tax_id"
                            disabled
                        />
                        </b-form-group>
                    </b-col>

                    <!-- Tax Office -->
                     <b-col lg="7">
                        <b-form-group
                        :label="$t('taxOffice')"
                        label-for="v-company-tax-office"
                        >
                        
                        <b-form-input
                            id="v-company-tax-office"
                            :placeholder="$t('taxOffice')"
                            v-model="supplierCompanyDetails.tax_office"
                            disabled
                        />
                        </b-form-group>
                    </b-col> 
                    </b-row>
                </b-form>
            </b-card>

            <div>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click="refetchDetails"
                    variant="primary"
                    class="mr-1"
                    >
                    <feather-icon
                        icon="RepeatIcon"
                        size="16"
                    />
                    {{ $t('update') }}
                </b-button>
            </div>
        </b-overlay>
    </b-col>
</b-row>


</ValidationObserver>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormText, BSpinner, VBModal, BCardText, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import moduleUser from '@/store/user/moduleUser.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BFormText,
    VBModal,
    BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading : true,
      user: null,
      supplierId :null,
    //   supplierCompanyDetails: {
    //     name: '',
    //     purpose: '',
    //     address: '',
    //     taxId: '',
    //     taxOffice: '',      
    //   },
      supplierCompanyDetails:{},
    }
  },
  methods: {
      refetchDetails(){       
          this.loading = true;
          this.$store.dispatch("user/getSupplierCompanyDetails", this.supplierId)        
          .then((data)=>{
            this.supplierCompanyDetails = data;  
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Company Details successfully updated!"),
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })          
          }) 
          .catch(()=>{this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Error fetching Company Details"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
          })
          .finally(()=>{this.loading = false})
      }

  },

  created() {
    this.loading = true;

    if (!store.hasModule('user')) store.registerModule('user', moduleUser)
    this.user = this.$store.state.user

    if(this.user==null){
        this.$store.dispatch("user/getUserDetails")
            .then( (user) => {
                this.user = user;                  
                })
            .catch(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t("Error fetching user"),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    this.supplierId = this.user.supplierCompanyDetails.supplierId; 
    let emptyObjectSupplierCompanyDetails = this.supplierCompanyDetails
        && Object.keys(this.supplierCompanyDetails).length === 0 
        && Object.getPrototypeOf(this.supplierCompanyDetails) === Object.prototype;

    if (emptyObjectSupplierCompanyDetails){
        this.$store.dispatch("user/getSupplierCompanyDetails", this.supplierId)
            .then((data)=>{
                this.supplierCompanyDetails = data;               
            }) 
            .catch(
                ()=> {return this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t("Error fetching Company Details"),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })  
            }) 
            .finally(()=>{
                this.loading = false
            })
    }
    else{
        this.loading = false
    }       
  },
}
</script>

<style lang="scss" >
    @import '@core/scss/vue/libs/vue-good-table.scss';
</style>